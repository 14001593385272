import React, { useState } from "react";
import {
  Box,
  Heading,
  VStack,
  Text,
  SimpleGrid,
  Button,
  Flex,
  keyframes,
  usePrefersReducedMotion,
  Divider,
} from "@chakra-ui/react";
import { FaCalendarAlt } from "react-icons/fa";
import { saveAs } from "file-saver"; // npm install file-saver for downloading files

// Animation for card glow effect
const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px rgba(255, 0, 0, 0.2); }
  50% { box-shadow: 0 0 20px rgba(255, 0, 0, 0.5); }
  100% { box-shadow: 0 0 5px rgba(255, 0, 0, 0.2); }
`;

// Function to generate the .ics file content
const createICSFile = (event) => {
  const icsContent = `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//YourApp//EN
BEGIN:VEVENT
UID:${new Date().getTime()}@yourapp.com
DTSTAMP:${new Date().toISOString().replace(/[-:]/g, "").split(".")[0]}
DTSTART:${
    new Date(event.dateStart).toISOString().replace(/[-:]/g, "").split(".")[0]
  }
DTEND:${
    new Date(event.dateEnd).toISOString().replace(/[-:]/g, "").split(".")[0]
  }
SUMMARY:${event.title}
DESCRIPTION:${event.description}
LOCATION:${event.location}
END:VEVENT
END:VCALENDAR
  `;
  return new Blob([icsContent], { type: "text/calendar" });
};

// Reusable EventCard component with "Add Reminder" feature
const EventCard = ({
  title,
  description,
  date,
  location,
  dateStart,
  dateEnd,
  animation,
  isUpcoming, // New prop to determine if the event is upcoming
}) => {
  const handleAddReminder = () => {
    const icsFile = createICSFile({
      title,
      description,
      location,
      dateStart,
      dateEnd,
    });
    saveAs(icsFile, `${title.replace(/\s/g, "_")}_event.ics`);
  };

  return (
    <Box
      bg="rgba(255, 255, 255, 0.1)"
      p={8}
      borderRadius="lg"
      display="flex"
      flexDirection="column"
      alignItems="center"
      transform="scale(1)"
      transition="transform 0.3s ease-out, background-color 0.3s"
      _hover={{
        transform: "scale(1.05)",
        bgGradient: "linear(to-r, red.500, yellow.400)",
      }}
      animation={animation}
    >
      <Heading
        as="h3"
        size="lg"
        color="red.300"
        mb={2}
        textAlign="center"
        textShadow="0 0 10px rgba(255, 0, 0, 0.8)"
      >
        {title}
      </Heading>
      <Divider my={3} borderColor="gray.600" />
      <Text
        fontSize="md"
        fontWeight="bold"
        textAlign="center"
        color="whiteAlpha.800"
        whiteSpace="pre-line"
        mb={4}
      >
        {description}
      </Text>
      {isUpcoming ? ( // Conditional rendering based on event type
        <Flex align="center" fontSize="md" fontWeight="bold" color="red.400">
          <FaCalendarAlt style={{ marginRight: "5px" }} />
          {date}
        </Flex>
      ) : (
        <Text fontSize="md" fontWeight="bold" color="red.400">
          {date}
        </Text>
      )}
      {isUpcoming && ( // Only show button for upcoming events
        <Button colorScheme="red" mt={4} onClick={handleAddReminder}>
          Add Reminder
        </Button>
      )}
    </Box>
  );
};

const Tour = () => {
  const [showUpcoming, setShowUpcoming] = useState(true);
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion
    ? undefined
    : `${glowAnimation} 2s ease-in-out infinite`;

  // Event data (with dateStart and dateEnd for reminders)
  const upcomingEvents = [
    {
      title: "Paris 🇫🇷",
      description: "L'ARC",
      date: "20/03",
      dateStart: "2023-10-16T18:00:00",
      dateEnd: "2023-10-16T21:00:00",
      location: "Paris, France",
    },
    // {
    //   title: "Paris 🇫🇷",
    //   description: "YOYO Palais de Tokyo - JOYA",
    //   date: "26/10",
    //   dateStart: "2023-10-26T18:00:00",
    //   dateEnd: "2023-10-26T21:00:00",
    //   location: "Paris, France",
    // },
    // {
    //   title: "Madrid 🇪🇸",
    //   description: "TBA",
    //   date: "29/10",
    //   dateStart: "2023-10-29T18:00:00",
    //   dateEnd: "2023-10-29T21:00:00",
    //   location: "Madrid, Spain",
    // },
    // {
    //   title: "Marseille 🇫🇷",
    //   description: "BONBONNE",
    //   date: "23/11",
    //   dateStart: "2023-11-23T18:00:00",
    //   dateEnd: "2023-11-23T21:00:00",
    //   location: "Marseille, France",
    // },
  ];
  

  const pastEvents = [
    {
      title: "Paris",
      description:
        "Bonnie\nBohème\nClub la Nuit\nYoyo Palais de Tokyo\nSilencio\nTaboo",
    },
    {
      title: "Tel Aviv",
      description:
        "Rabbit\nAria\nGoat\nJimmy Who\nSecret Garden\nMatta\nFrishman Beach",
    },
    { title: "London", description: "B London\nMaddox Club\nDear Darling" },
    { title: "Madrid", description: "Toy Room\nIstar\nLos Amantes" },
    { title: "Tulum", description: "Mia" },
    { title: "Marseille", description: "Bonbonne" },
  ];

  return (
    <Box
      bgGradient="linear(to-r, black, gray.900)"
      color="white"
      minH="100vh"
      p={8}
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="relative"
      overflow="hidden"
    >
      <Heading
        as="h1"
        size="2xl"
        mb={8}
        mt={["80px", "80px", "100px", "100px"]}
        color="red.500"
        textAlign="center"
      >
        Tour Dates
      </Heading>

      <Flex justify="center" mb={6}>
        <Button
          colorScheme="red"
          variant={showUpcoming ? "solid" : "outline"}
          onClick={() => setShowUpcoming(true)}
          mr={4}
        >
          Upcoming Events
        </Button>
        <Button
          colorScheme="red"
          variant={!showUpcoming ? "solid" : "outline"}
          onClick={() => setShowUpcoming(false)}
        >
          Past Events
        </Button>
      </Flex>

      <Box
        position="absolute"
        top={0}
        left={0}
        width="100vw"
        height="100vh"
        bgGradient="linear(to-b, black, gray.800)"
        zIndex="-1"
        opacity={0.2}
        backgroundImage="url('https://www.transparenttextures.com/patterns/noise-pattern.png')"
        animation="particleMovement 30s infinite linear"
      />

      {/* Section des événements */}
      <Box w="100%" maxW="1000px" mt={12}>
        <Heading as="h2" size="xl" mb={6} color="red.400" textAlign="center">
          {showUpcoming ? "Upcoming Events" : "Past Events"}
        </Heading>

        <VStack spacing={6} align="stretch">
          <SimpleGrid columns={1} spacing={10}>
            {(showUpcoming ? upcomingEvents : pastEvents).map(
              (event, index) => (
                <EventCard
                  key={index}
                  title={event.title}
                  description={event.description}
                  date={showUpcoming ? event.date : ""} // Only show date for upcoming events
                  dateStart={event.dateStart}
                  dateEnd={event.dateEnd}
                  location={event.location}
                  animation={animation}
                  isUpcoming={showUpcoming} // Pass the new prop
                />
              )
            )}
          </SimpleGrid>
        </VStack>
      </Box>
    </Box>
  );
};

export default Tour;
